.game-page {
  background-color: #faf9f6;
  width: 90%;
  left: 5%;
  position: relative;
  padding-bottom: 5rem;
  /* height: 100vh; */
}
.create-game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  position: relative;
}

.categories-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  position: relative;
}

.category-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
}

.category-questions {
  flex-direction: column;
  text-align: center;
}

input[type="text"] {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  margin-right: 0.25rem;
  flex-grow: 1;
}

.remove-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  border-radius: 50%;
  border: none;
  background-color: #ccc;
  color: #fff;
  cursor: pointer;
}

.add-button {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.submit-button {
  padding: 0.5rem;
  font-size: 1rem;
  color: #fff;
  border-radius: 0.25rem;
  border: none;
  background-color: #007bff;
}
.game-board {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.question-container {
  position: relative;
  /* font-size: 20px; */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  overflow: hidden;
  margin-top: 1.5rem;
}

.question-value {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  color: #007bff;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.question-item {
  width: 250px;
  height: 250px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size:0.5rem;
  padding-top:20px;
  padding-bottom: 20px;
  /* position: relative; */
}

.question-item .card {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  font-size: 0.5rem;
}

.question-item .front,
.question-item .back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.question-item .front {
  z-index: 2;
  background-color: #65a3dd;
  color: #fff;
}

.question-item .back {
  transform: rotateY(-180deg);
  z-index: 1;
  background-color:#115ff4;
  color: #fff;
}

.question-item.flipped .card {
  transform: rotateY(-180deg);
}

.question-item.flipped .front {
  display: none;
}

p{
  font-size: 1.25rem;
}

/*  */

.card-value {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color:#ebebeb;
}

.card-value-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.processing-icon {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.new-game-button {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: none;
  background-color: #007bff;
  color: #fff;
  margin-top: 5rem;
}

.page-header {
  text-align: center;
  padding-top: 5rem;
  color:  #65a3dd;
}

.category-title {
  text-align: center;
  color: rgb(110, 110, 110);
}

.question-item {
  position: relative;
  overflow: hidden;
  /* margin: 0.5rem; */
  display: inline-block;
  vertical-align: top;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  overflow: auto;
}

.question-item.expanded {
  position: fixed;
  z-index: 999;
  overflow: visible;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.8);
  height: 25vh;
  width: 25vw;
}

.question-item.centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.exit-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border: none;
  padding: 0.25rem;
  font-weight: bold;
  cursor: pointer;
}